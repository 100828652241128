import state from './moduleProviderState.js'
import mutations from './moduleProviderMutations.js'
import actions from './moduleProviderActions.js'
import getters from './moduleProviderGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

