import Vue from 'vue'

export default {
  //MANIPULA A ÚNICA CONTA OBJETO FORA DO ARRAY DE CONTAS NO ESTADO
  SET (state, payload) {
    state.provider = Object.assign({}, state.provider, payload) //precisa ser assim para manter a reatividade
  },
  SET_PROVIDERS (state, payload) {
    state.providers = payload
  },
  SET_PROVIDERS_GRID (state, payload) {
    state.providersGrid = payload
  },
  //ADICIONA UMA NOVA CONTA NO ARRAY DE CONTAS
  ADD (state, payload) {
    state.providers.push(payload) //precisa ser assim para manter a reatividade
    state.providers.sort((a, b) => { return a.name.localeCompare(b.name) }) // Ordena por nome
  },
  //SUBSTITUI O ARRAY DE CONTAS POR UM NOVO
  REFRESH (state, payload) {
    state.providers = payload
  },
  /////////////////////// USADOS NO GRID MÓDULO CADASTROS
  STORE (state, payload) {
    state.providersGrid.data.push(payload)
    state.providersGrid.data.sort((a, b) => { return a.name.localeCompare(b.name) }) // Ordena por nome
    state.providersGrid.total = state.providersGrid.total + 1
  },
  UPDATE (state, payload) {
    const idx = state.providers.findIndex(o => o.id === payload.id)
    if (idx >= 0) Vue.set(state.providers, idx, payload)

    const idx1 = state.providersGrid.data.findIndex(o => o.id === payload.id)
    if (idx1 >= 0) Vue.set(state.providersGrid.data, idx1, payload)
  },
  DELETE (state, payload) {
    if (state.provider && state.provider.id === payload) {
      state.provider = Object.assign({}, {}) //precisa ser assim para manter a reatividade
    }

    const idx1 = state.providers.findIndex(o => o.id === payload)
    if (idx1 >= 0) state.providers.splice(idx1, 1) //precisa ser assim para manter a reatividade

    const idx = state.providersGrid.data.findIndex(o => o.id === payload)
    if (idx >= 0) {
      state.providersGrid.data.splice(idx, 1) //precisa ser assim para manter a reatividade
      state.providersGrid.total = state.providersGrid.total - 1
    }
  }
}
