export default {
  provider: {
    address: {}
  },
  providers: [],
  providersGrid: {
    data: [],
    total: 0
  }
}
