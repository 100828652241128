<template>
  <div>
    <vs-popup class="popup90" :fullscreen="false" title="Cadastrar Despesas" :active.sync="localShow">

      <permission-password style="z-index: 9999999999;" :show="popupPermissionPassword" title="ALERTA DE BAIXA RETROATIVA" icon="warning"
        actionButtonText="Prosseguir"
        @action="storeDebit(true)"
        @cancel="popupPermissionPassword = false">
      </permission-password>

      <!-- CADASTRO DE FORNECEDOR -->
      <vs-popup class="popup90" :fullscreen="false" title="Cadastrar Fornecedor" :active.sync="popupProvider">
        <div class="vx-row">
          <div class="vx-col md:w-1/5 w-full mt-0">
            <label class="vs-input--label">Tipo de Pessoa</label>
            <v-select v-model="provider.type" :reduce="option => option.value" :clearable="false"
              :options="[
                {label: 'Física', value: 'FISICA'},
                {label: 'Jurídica', value: 'JURIDICA'}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-2/5 w-full mt-0">
            <vs-input class="w-full mt-0" label="Nome/Razão Social" v-model="provider.name" data-vv-scope="provider" data-vv-as="Nome/Razão Social" v-validate.initial="'required'" name="name" />
            <span class="text-danger text-sm" v-show="errors.has('provider.name')">{{ errors.first('provider.name') }}</span>
          </div>
          <div class="vx-col md:w-1/5 w-full mt-0">
            <vs-input class="w-full" label="CNPJ/CPF" v-model="provider.cpf" v-mask="['###.###.###-##','##.###.###/####-##']" data-vv-scope="provider" data-vv-as="CPF" v-validate="'cpf'" name="cpf" />
            <span class="text-danger text-sm" v-show="errors.has('provider.cpf')">{{ errors.first('provider.cpf') }}</span>
          </div>
          <div class="vx-col md:w-1/5 w-full mt-0">
            <vs-input class="w-full" label="IE/RG" v-model="provider.rg" />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-1/5 w-full" v-for="(phone,i) in provider.phones" :key="i">
            <vs-input class="w-full mt-2" v-mask="['(##)#####-####','(##)####-####']" :label="phone.type === 'phone' ? 'Telefone' : phone.type === 'mobile' ? 'Celular' : phone.type === 'other' ? 'Outro' : ''" v-model="phone.phone" />
          </div>
          <div class="vx-col md:w-1/5 w-full">
            <vs-input class="w-full mt-2" label="E-mail" data-vv-as="E-mail" v-model="provider.email" data-vv-scope="provider" type="email" v-validate="'email'" name="email" />
            <span class="text-danger text-sm" v-show="errors.has('provider.email')">{{ errors.first('provider.email') }}</span>
          </div>
          <div class="vx-col md:w-1/5 w-full mt-2">
            <label class="vs-input--label">Plano de contas</label>
            <v-select class="vue_select_drop_size_180" v-model="provider.account_plan_id"
              data-vv-as="Plano de Contas" data-vv-scope="provider" v-validate.initial="'required'" name="account_plan_id"
              :reduce="option => option.value" :options="accountPlans" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
            <span class="text-danger text-sm" v-show="errors.has('provider.account_plan_id')">Campo Obrigatório</span>
          </div>
        </div>

        <div class="flex items-end mt-2">
          <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" />
          <span class="leading-none font-medium">Endereço</span>
        </div>
        <hr>

        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mt-2">
            <vs-input class="w-full" label="CEP" placeholder="CEP" v-model="provider.address.zip_code" v-mask="['#####-###']" @keyup="searchZipCode" />
          </div>

          <div class="vx-col md:w-1/4 w-full mt-2">
            <label class="vs-input--label">Logradouro</label>
            <v-select v-model="provider.address.logradouro" :reduce="option => option.value" clearable :options="logradouroOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>

          <div class="vx-col md:w-1/4 w-full mt-2">
            <vs-input class="w-full" label="Endereço" v-model="provider.address.street" />
          </div>

          <div class="vx-col md:w-1/4 w-full mt-2">
            <vs-input class="w-full" label="Número" v-model="provider.address.number" type="number" placeholder="S/N" />
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/5 w-full">
            <vs-input class="w-full mt-2" label="Complemento" v-model="provider.address.complement" />
          </div>
          <div class="vx-col md:w-1/5 w-full">
            <vs-input class="w-full mt-2" label="Bairro" v-model="provider.address.neighborhood" />
          </div>
          <div class="vx-col md:w-1/4_ w-full mt-2">
            <label class="vs-input--label">Cidade</label>
            <v-select v-model="provider.address.city_id" :reduce="option => option.value" :placeholder="cityOptions.length ? 'Selecione' : 'Selecione um estado'" :options="cityOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <label class="vs-input--label">Estado</label>
            <v-select v-model="provider.address.state_id" :reduce="option => option.value" @input="fillCity(provider.address.state_id), provider.address.city_id = null" :options="states" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <label class="vs-input--label">País</label>
            <v-select v-model="provider.address.country" :reduce="option => option.value" :clearable="false" :options="countryOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
        </div>

        <vs-divider></vs-divider>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mt-4 flex flex-wrap items-center justify-end">
              <vs-button color="secondary" class="ml-auto" @click="popupProvider = false">Cancelar</vs-button>
              <vs-button class="ml-4" @click="storeProvider" :disabled="!validateProvider || disableSave">Salvar Alterações</vs-button>
            </div>
          </div>
        </div>

      </vs-popup>

      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Pagar Para</label>

          <div class="mt-0 flex flex-wrap items-center justify">

          <v-select v-model="debit.student_id" @option:selected="selectedProvider" @search="debouncedGetSearchProvider" :clearable="false" :filterable="false"
            :reduce="option => option.value" :options="providerOptions" data-vv-as="Fornecedor" data-vv-scope="debit" v-validate.initial="'required'" name="student"
            placeholder="Digite CPF/CNPJ ou nome do fornecedor/aluno..." :dir="$vs.rtl ? 'rtl' : 'ltr'"
            class="w-90__">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
          <feather-icon @click="popupProvider = true" title="Novo Fornecedor" icon="UserPlusIcon" class="ml-auto inline-flex rounded-full" svgClasses="w-8 h-8 cursor-pointer hover:text-primary"></feather-icon>
          </div>
          <span class="text-danger text-sm" v-show="errors.has('debit.student')">Campo obrigatório</span>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <vs-input class="w-full" label="Valor Total" ref="total" v-model="debit.total" v-currency="currencyConfig" />
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <label class="vs-input--label">Nº de Parcelas</label>
          <vs-input-number min="1" class="mt-2" v-model="debit.parcelsNumber" @input="recalculateTotal"
            data-vv-as="Parcelas" data-vv-scope="debit" v-validate="'required|min_value:1'" name="parcelsNumber" />
          <span class="text-danger text-sm" v-show="errors.has('debit.parcelsNumber')">{{ errors.first('debit.parcelsNumber') }}</span>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <vs-input class="w-full mt-0" label="Valor 1ª Parcela" ref="firstParcelValue"
            :disabled="debit.parcelsNumber < 2"
            v-model="debit.firstParcelValue" v-currency="currencyConfig" />
          <span class="text-danger text-sm" v-if="errorfirstParcelValue">Este valor é maior que o total.</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input type="date" class="w-full" label="1º Vencimento" v-model="debit.expirationDateFirstParcel"
            data-vv-as="1º Vencimento" data-vv-scope="debit" v-validate.initial="'required'" name="expirationDateFirstParcel" />
          <span class="text-danger text-sm" v-show="errors.has('debit.expirationDateFirstParcel')">Este campo é obrigatório.</span>
          <span class="text-danger text-sm" v-if="!isSameOrAfterToday(debit.expirationDateFirstParcel)">A primeira parcela já está vencida.</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Periodicidade</label>
          <v-select v-model="debit.frequency" :reduce="option => option.value" :clearable="false" :options="[{label: 'Mensal', value: 'monthly'}, {label: 'Quinzenal', value: 'biweekly'}, {label: 'Semanal', value: 'weekly'}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"></v-select>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Tipo de Pagamento</label>
          <v-select class="vue_select_drop_size_180" @input="filterAccounts" v-model="debit.type_payment_id" :reduce="option => option.value" :clearable="false"
            data-vv-as="Tipo de Pagamento" data-vv-scope="debit" v-validate.initial="'required'" name="type_payment_id"
            :options="typePayments" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('debit.type_payment_id')">Este campo é obrigatório.</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Conta</label>
          <v-select class="vue_select_drop_size_180" :disabled="!debit.type_payment_id" v-model="debit.account" :reduce="option => option.value" :clearable="false"
            data-vv-as="Conta" data-vv-scope="debit" v-validate.initial="'required'" name="account"
            :options="accountsFilteredOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('debit.account')">Este campo é obrigatório.</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Plano de contas</label>
          <v-select class="vue_select_drop_size_180" data-vv-scope="debit" v-model="debit.account_plan" :reduce="option => option.value" :options="accountPlans" placeholder="Selecione"
            :dir="$vs.rtl ? 'rtl' : 'ltr'" v-validate.initial="'required'" >
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
           <span class="text-danger text-sm" v-show="!debit.account_plan || errors.has('debit.account_plan')" >Este campo é obrigatório.</span>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-4/5 w-full mt-2">
          <vs-input class="w-full" label="Descrição" v-model="debit.observation" />
        </div>
        <div class="vx-col md:w-1/5 w-full mt-6">
          <div class="mt-0 flex flex-wrap items-center justify-end">
            <vs-button @click="generateParcels" :disabled="disableGenerateParcels" class="w-full mt-1" type="border" color="warning" icon-pack="feather" icon="icon-dollar-sign">Gerar Parcelas</vs-button>
          </div>
        </div>
      </div>

      <vs-divider position="left">
        <feather-icon icon="DollarSignIcon" class="mr-0" svgClasses="w-4 h-4" /><span class="mb-4">Parcelas</span>
      </vs-divider>

      <!-- PARCELAS -->
      <vs-table v-if="debit.transactions.length" :data="debit.transactions" hoverFlat style="overflow: -webkit-paged-y">
        <template slot="thead">
          <vs-th>#</vs-th>
          <vs-th>Valor</vs-th>
          <vs-th>Vencimento</vs-th>
          <vs-th>Forma Pagamento</vs-th>
          <vs-th>Conta</vs-th>
          <vs-th>Situação</vs-th>
          <vs-th>Pago Em</vs-th>
          <!-- <vs-th>Plano de Contas</vs-th> -->
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="i" v-for="(parcel, i) in data" style="opacity: 1 !important">
            <vs-td> {{ i + 1 }} </vs-td>
            <vs-td>
                <currency-input class="w-32 vs-inputx vs-input--input normal hasValue"
                  @input="parcel.situation ? parcel.value_pay = parcel.value_parcel : parcel.value_pay = 0;"
                  v-model="parcel.value_parcel"
                  :currency="{prefix: 'R$ '}"
                  :valueAsInteger="false"
                  :distractionFree="false"
                  :precision="2"
                  :autoDecimalMode="true"
                  :valueRange="{ min: 0 }"
                  :allowNegative="false" />
            </vs-td>
            <vs-td>
              <vs-input type="date" class="w-48" v-model="parcel.expiration_date"
                data-vv-as="Vencimento" data-vv-scope="debit" v-validate.initial="{ required: true }" :name="'expiration_date'+i" />
              <span class="text-danger text-sm" v-show="errors.has('debit.expiration_date'+i)">Campo obrigatório</span>
            </vs-td>
            <vs-td>
              <v-select class="vue_select_drop_size_100 w-48" :clearable="false" @input="filterAccountsParcels(i)" v-model="parcel.type_payment_id" :reduce="option => option.value" :options="typePayments" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </vs-td>
            <vs-td>
              <v-select class="vue_select_drop_size_100 w-48" :clearable="false" :disabled="!parcel.type_payment_id" v-model="parcel.account"
                :reduce="option => option.value" :options="parcel.accountsFilteredOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                data-vv-as="Conta" data-vv-scope="debit" v-validate.initial="{ required: !parcel.account }" :name="'account'+i">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
              <span class="text-danger text-sm" v-show="errors.has('debit.account'+i)">Campo obrigatório</span>
            </vs-td>
            <vs-td>
              <div class="flex flex-wrap items-center justify-center">
                <vs-switch class="w-28 mt-2" icon-pack="feather" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash"
                  @input="parcel.situation ? parcel.value_pay = parcel.value_parcel : parcel.value_pay = 0;
                  parcel.situation ? parcel.payday = today : parcel.payday = ''
                  parcel.situation ? '' : parcel.futureDate = false;
                  checkPayday(parcel)" v-model="parcel.situation">
                  <span slot="off">ABERTA</span> <span slot="on">QUITADA</span>
                </vs-switch>
              </div>
            </vs-td>
            <vs-td>
              <vs-input type="date" class="w-48" :disabled="!parcel.situation" v-model="parcel.payday" @change="checkPayday(parcel)"
              data-vv-as="Pago em" data-vv-scope="debit" v-validate.initial="{ required: parcel.situation }" :name="'payday'+i" />
              <span class="text-danger text-sm" v-show="errors.has('debit.payday'+i)">Campo obrigatório</span>
              <span class="text-danger text-sm" v-show="parcel.futureDate">Data futura não permitida</span>
            </vs-td>
            <!-- <vs-td>
              <v-select class="vue_select_drop_size_100 w-48" :clearable="false" v-model="parcel.account_plan" :reduce="option => option.value" :options="accountPlans" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </vs-td> -->
          </vs-tr>

          <vs-tr style="opacity: 1 !important">
            <vs-td colspan="8">
              <vs-divider></vs-divider>
              <div class="vx-col w-full">
                <div class="mt-2 flex flex-wrap items-center">
                  <vs-button class="mr-4 mb-2" color="secondary" @click="localShow = false">Cancelar</vs-button>
                  <vs-button class="mr-auto mb-2" @click="storeDebit()" :disabled="!validateDebit || disableGenerateParcels || disableSave">Salvar</vs-button>
                </div>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-alert v-if="!debit.transactions.length" class="mt-4 mb-4" :active="true" color="warning" icon-pack="feather" icon="icon-info" style="height: auto">
        <span>Por favor preencha o formulário e gere as parcelas para poder cadastrar!</span>
      </vs-alert>
    </vs-popup>

  </div>
</template>

<script>
// Store Module
import moduleTypePayment from '@/store/type-payment/moduleTypePayment.js'
import moduleAccount from '@/store/account/moduleAccount.js'
import moduleAccountPlan from '@/store/account-plan/moduleAccountPlan.js'
import moduleStates from '@/store/state/moduleStates.js'
import moduleProvider from '@/store/provider/moduleProvider.js'
import moduleGeneralCash from '@/store/general-cash/moduleGeneralCash.js'

import axios from '@/axios.js'
import _ from 'lodash'
import moment from 'moment'
import { setValue, getValue, CurrencyDirective, CurrencyInput } from 'vue-currency-input'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
const permissionPassword = () => import(/* webpackChunkName: "permissionPassword" */ '@/components/permission-password/permissionPassword.vue')

//AJUSTE DE VENCIMENTOS 30 DIAS EM MESES COM MENOS DE 31 DIAS
Date.isLeapYear = function (year) {
  return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0))
}
Date.getDaysInMonth = function (year, month) {
  return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month]
}
Date.prototype.isLeapYear = function () {
  return Date.isLeapYear(this.getFullYear())
}
Date.prototype.getDaysInMonth = function () {
  return Date.getDaysInMonth(this.getFullYear(), this.getMonth())
}
Date.prototype.addMonths = function (value) {
  const n = this.getDate()
  this.setDate(1)
  this.setMonth(this.getMonth() + value)
  this.setDate(Math.min(n, this.getDaysInMonth()))
  // return `${this.getFullYear()}-0${this.getMonth() + 1}-${this.getDate()}`
  return this
}

export default {
  components: {
    vSelect,
    CurrencyInput,
    permissionPassword
  },
  props: {
    show: {
      type: Boolean, default: false
    }
  },

  computed: {
    validateDebit () {
      return !this.errors.any('debit') && !this.errorfirstParcelValue
    },
    validateProvider () {
      return !this.errors.any('provider')
    },
    firstValue () { //controlo no watch para não permitir valor maior que o total
      return this.debit.firstParcelValue
    },
    firstParcelValue () {
      return this.debit.transactions[0] && this.debit.transactions[0].value_parcel
    },
    typePayments () {
      return this.$store.getters['typePayment/forSelect']
    },
    accountPlans () {
      return this.$store.getters['accountPlan/forSelectFilterDespesa']
    },
    accounts () {
      return this.$store.getters['account/forSelect']
    },
    total () { // apenas para o watch
      return this.debit.total
    },
    states () {
      return this.$store.getters['states/getStates']
    },
    today () {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    }
  },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.$emit('cancel')
        this.resetModal() //reseta o form ao fechar a modal
      } else {
        this.$store.dispatch('typePayment/fetchAll').then(() => { }).catch(err => { console.error(err) })
        this.$store.dispatch('account/fetchAll').then(() => { }).catch(err => { console.error(err) })
        this.$store.dispatch('accountPlan/fetchAll').then(() => { }).catch(err => { console.error(err) })
        this.$store.dispatch('states/fetchStates')
        this.debit.expirationDateFirstParcel = this.today
      }
    },
    total () {
      this.recalculateTotal()
    },
    firstParcelValue (newValue) {
      this.recalculateParcelsValue(newValue)
    },
    firstValue () {
      const value = getValue(this.$refs.firstParcelValue)
      this.debit.transactions = []
      if (value > getValue(this.$refs.total)) {
        this.errorfirstParcelValue = true
      } else {
        this.errorfirstParcelValue = false
      }
    }
  },

  created () {
    this.debouncedGetSearchProvider = _.debounce(this.fetchproviderOptions, 600)

    if (!moduleTypePayment.isRegistered) {
      this.$store.registerModule('typePayment', moduleTypePayment)
      moduleTypePayment.isRegistered = true
    }
    if (!moduleAccount.isRegistered) {
      // this.$store.registerModule('account', moduleAccount)
      moduleAccount.isRegistered = true
    }
    if (!moduleAccountPlan.isRegistered) {
      // this.$store.registerModule('accountPlan', moduleAccountPlan)
      moduleAccountPlan.isRegistered = true
    }
    if (!moduleGeneralCash.isRegistered) {
      this.$store.registerModule('generalCash', moduleGeneralCash)
      moduleGeneralCash.isRegistered = true
    }
    if (!moduleStates.isRegistered) {
      this.$store.registerModule('states', moduleStates)
      moduleStates.isRegistered = true
    }
    if (!moduleProvider.isRegistered) {
      this.$store.registerModule('provider', moduleProvider)
      moduleProvider.isRegistered = true
    }

    //ESTADO DEFAULT SC
    this.provider.address.state_id = 24
    this.fillCity(this.provider.address.state_id)

  },

  data () {
    return {
      localShow: false,
      popupProvider: false,
      popupPermissionPassword: false,

      disableSave: false,
      disableGenerateParcels: false,

      errorfirstParcelValue: false,
      currencyConfig:  {
        currency: {prefix: 'R$ '},
        valueAsInteger: false,
        distractionFree: false,
        precision: 2,
        autoDecimalMode: true,
        valueRange: { min: 0 },
        allowNegative: false
      },
      accountsFilteredOptions: [],
      providerOptions: [],

      debit: {
        student_id: null,
        account: null,
        type_payment_id: null,
        account_plan: null,
        parcelsNumber: 1,
        total: 0,
        firstParcelValue: 0,
        expirationDateFirstParcel: '',
        frequency: 'monthly',
        applyInterest: true,
        observation: null,

        transactions: []
      },

      provider: {
        type: 'JURIDICA',
        name: null,
        cpf: null,
        rg: null,
        phones: [
          {type: 'mobile', phone: null},
          {type: 'phone', phone: null},
          {type: 'other', phone: null}
        ],
        email: null,
        account_plan_id: null,
        address: {
          type_address: 'COM',
          zip_code: null,
          logradouro: null,
          street: null,
          number: null,
          complement: null,
          neighborhood: null,
          city_id: null,
          state_id: null,
          country: 1 //Brasil
        }
      },
      countryOptions: [{ label: 'Brasil', value: 1 }],
      cityOptions:[],
      logradouroOptions: [
        { label: 'Rua', value: 'Rua' },
        { label: 'Avenida', value: 'Avenida' },
        { label: 'Rodovia', value: 'Rodovia' },
        { label: 'Estrada', value: 'Estrada' },
        { label: 'Lote', value: 'Lote' },
        { label: 'Servidão', value: 'Servidão' },
        { label: 'Quadra', value: 'Quadra' },
        { label: 'Travessa', value: 'Travessa' },
        { label: 'Linha', value: 'Linha' }
      ]
    }
  },

  directives: { currency: CurrencyDirective },

  methods: {
    //EVITA BAIXA COM DATA FUTURA
    checkPayday (parcel) {
      if (moment(parcel.payday).year() > 1000) {
        const difDays = moment(parcel.payday).diff(moment(this.today), 'days')
        if (difDays > 0) {
          parcel.futureDate = true
          this.disableSave = true
        } else {
          parcel.futureDate = false
          //checa se há alguma parcela com pagamento futuro
          const index = this.debit.transactions.findIndex(e => e.futureDate)
          if (index === -1) this.disableSave = false
        }
      } else {
        parcel.futureDate = false
        //checa se há alguma parcela com pagamento futuro
        const index = this.debit.transactions.findIndex(e => e.futureDate)
        if (index === -1) this.disableSave = false
      }
    },
    cancel () {
      this.$emit('cancel')
    },
    resetModal () {
      this.debit.student_id = null
      this.debit.account = null
      this.debit.type_payment_id = null
      this.debit.account_plan = null
      this.debit.parcelsNumber = 1
      setValue(this.$refs.total, 0)
      setValue(this.$refs.firstParcelValue, 0)
      this.debit.expirationDateFirstParcel = ''
      this.debit.frequency = 'monthly'
      this.debit.applyInterest = true
      this.debit.observation = null
      this.debit.transactions = []
    },
    recalculateTotal () {
      this.debit.transactions = [] //evita alterar valores e esquecer de gerar novamente as parcelas
      if (parseInt(this.debit.parcelsNumber) === 1) {
        setValue(this.$refs.firstParcelValue, getValue(this.$refs.total))
      } else {
        setValue(this.$refs.firstParcelValue, 0)
      }
    },
    recalculateParcelsValue (firstParcelValue) {
      if (this.debit.transactions.length) { //evita erro
        if (firstParcelValue > getValue(this.$refs.total)) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O valor digitado é maior que o valor total do contrato.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })

          this.debit.transactions[0].value_parcel = 0
          return
        }

        const valueParcels = (getValue(this.$refs.total) - firstParcelValue) / (parseInt(this.debit.parcelsNumber) - 1)
        for (let parcel = 1; parcel < (this.debit.parcelsNumber); parcel++) {
          this.debit.transactions[parcel].value_parcel = valueParcels
          if (this.debit.transactions[parcel].situation) {
            this.debit.transactions[parcel].value_pay = this.debit.transactions[parcel].value_parcel
          } else {
            this.debit.transactions[parcel].value_pay = 0
          }
        }
      }
    },
    fetchproviderOptions (search, loading) {
      if (search.length) {
        loading(true)
        // busco alunos e fornecedores
        axios.post(`${process.env.VUE_APP_API_URL}/student/search`, { kw: search, type: null })
          .then(function (response) {
            this.providerOptions = []
            for (const k in response.data) {
              this.providerOptions.push({
                value: response.data[k].id,
                label: `${response.data[k].name} ${response.data[k].cpf || ''}`,
                account_plan_id: response.data[k].account_plan_id
              })
            }
            loading(false)
          }.bind(this))
          .catch(function (error) {
            console.error(error)
          })

      } else {
        loading(false)
      }
    },
    isSameOrAfterToday (e) {
      if (moment(e).isValid()) return moment(e).isSameOrAfter(moment().format('YYYY-MM-DD'))
      return true
    },
    // FILTRA AS CONTAS CONFORME O TIPO DE PAGAMENTO
    filterAccounts () {
      this.debit.account = null
      const myArrayFiltered = this.accounts.filter((account) => {
        return account.type_payments.some(e => e.id === this.debit.type_payment_id)
      })
      this.accountsFilteredOptions = myArrayFiltered
      if (myArrayFiltered.length) this.debit.account = myArrayFiltered[0].value
    },
    // FILTRA AS CONTAS NO FOR DAS PARCELAS CONFORME O TIPO DE PAGAMENTO
    filterAccountsParcels (i) {
      const parcel = this.debit.transactions[i]
      parcel.account = null
      const myArrayFiltered = this.accounts.filter((account) => {
        return account.type_payments.some(e => e.id === parcel.type_payment_id)
      })
      parcel.accountsFilteredOptions = myArrayFiltered
      if (myArrayFiltered.length) parcel.account = myArrayFiltered[0].value
    },
    generateParcels () {
      this.disableGenerateParcels = true
      setTimeout(() => { this.disableGenerateParcels = false }, 2000) //TRAVA O BOTÃO POR 3 SEGUNDOS
      if (!this.validateDebit) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Por favor confira o preenchimento do formulário.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      this.debit.transactions = []

      const valueParcel = getValue(this.$refs.total) / parseInt(this.debit.parcelsNumber)
      let expirationDate = new Date(`${this.debit.expirationDateFirstParcel}T00:00:00`)

      for (let parcel = 1; parcel <= this.debit.parcelsNumber; parcel++) {
        if (parcel > 1) {
          if (this.debit.frequency === 'monthly') {
            expirationDate = new Date(`${this.debit.expirationDateFirstParcel}T00:00:00`).addMonths(parcel - 1)
          } else if (this.debit.frequency === 'biweekly') expirationDate.setDate(expirationDate.getDate() + 15)
          else if (this.debit.frequency === 'weekly') expirationDate.setDate(expirationDate.getDate() + 7)
        }
        this.debit.transactions.push({
          futureDate: false,
          type: 'DEBIT',
          // eslint-disable-next-line
          expiration_date: `${expirationDate.getFullYear()}-${('0' + (expirationDate.getMonth()+1)).slice(-2)}-${('0' + (expirationDate.getDate())).slice(-2)}`,
          payday: !(this.accountsFilteredOptions.find(o => o.card_account)) ? '' : this.today,
          value_pay: !(this.accountsFilteredOptions.find(o => o.card_account)) ? 0 : valueParcel,
          parcel,
          value_parcel: valueParcel,
          situation: !!(this.accountsFilteredOptions.find(o => o.card_account)), // false = PENDING | true = PAID
          value_total: getValue(this.$refs.total),
          observation: this.debit.observation,
          account_plan: this.debit.account_plan,
          account: this.debit.account,
          type_payment_id: this.debit.type_payment_id,
          accountsFilteredOptions: this.accountsFilteredOptions
        })
      }

      //ESTE BLOCO SERVE APENAS PARA CASOS EM QUE A PRIMEIRA PARCELA É DEFINIDA ANTES DE GERAR AS PARCELAS
      //CASO NÃO HAJA UM VALOR INICIAL DE PRIMEIRA PARCELA COMENTAR O BLOCO É O SUFICIENTE
      const v = getValue(this.$refs.firstParcelValue)
      if (this.debit.parcelsNumber > 1 && v > 0) {
        this.debit.transactions[0].value_parcel = v
        this.recalculateParcelsValue(v)
      }
    },
    storeDebit (pass = false) {
      /**
       * CHECAR PAGAMENTO RETROATIVO
       */
      if (!pass) {
        const retroactiveParcels = this.debit.transactions.filter(e => {
          if (moment(e.payday).diff(moment(this.today), 'days') < 0) return true
        })

        if (retroactiveParcels.length) {
          this.popupPermissionPassword = true
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'Há parcelas com baixa retroativa',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }
      }
      // Loading
      this.$vs.loading()
      this.disableGenerateParcels = true
      setTimeout(() => { this.disableGenerateParcels = false }, 2000) //TRAVA O BOTÃO SALVAR

      // É PRECISO TRATAR PARA QUE O CONTRATO RECEBA CORRETAMENTE O CAMPO servicePackages
      const debit =  JSON.parse(JSON.stringify(this.debit))
      debit.total = getValue(this.$refs.total)

      this.$store.dispatch('generalCash/store', debit)
        .then(() => {

          this.$emit('saved')
          this.localShow = false

          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 6000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })
    },
    //PROVIDER
    selectedProvider () {
      const provider = this.providerOptions.find(e => e.value === this.debit.student_id)
      if (provider) this.debit.account_plan = provider.account_plan_id
    },
    storeProvider () {
      if (!this.validateProvider) return

      // Loading
      this.$vs.loading()
      this.disableSave = true
      setTimeout(() => { this.disableSave = false }, 3000) //TRAVA O BOTÃO SALVAR POR 3 SEGUNDOS

      this.$store.dispatch('provider/store', this.provider)
        .then((response) => {
          this.$vs.loading.close()
          this.popupProvider = false
          this.providerOptions.push({
            value: response.data.id,
            label: `${response.data.name} ${response.data.cpf}`,
            account_plan_id: response.data.account_plan_id
          })
          this.debit.student_id = response.data.id
          this.debit.account_plan = response.data.account_plan_id

          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })
    },

    async searchZipCode ($event) {
      const zip_code = this.provider.address.zip_code.trim().replace(/[^0-9]/g, '')

      if (zip_code.length === 8 && ($event.keyCode < 37 || $event.keyCode > 40)) { // $event.keyCode evita chamadas de API ao teclar setas

        axios._noHeaders = true //enviando um get sem Autorization no header
        const resp = await axios.get(`https://viacep.com.br/ws/${zip_code}/json`)
        if (resp.data.erro) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: 'Este CEP não existe ou não foi encontrado.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }

        this.provider.address.neighborhood = resp.data.bairro
        this.provider.address.complement = resp.data.complemento

        //abaixo testo a primeira palavra da rua (logradouro), se existir removo da rua e seleciono o logradouro
        const rua = /Rua /i
        const avenida = /Avenida /i
        const rodovia = /Rodovia /i
        const estrada = /Estrada /i
        const lote = /Lote /i
        const servidao = /Servidão /i
        const quadra = /Quadra /i
        const travessa = /Travessa /i
        const linha = /Linha /i

        if (rua.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Rua'
          const regEx = new RegExp('Rua ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (avenida.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Avenida'
          const regEx = new RegExp('Avenida ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (rodovia.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Rodovia'
          const regEx = new RegExp('Rodovia ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (estrada.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Estrada'
          const regEx = new RegExp('Estrada ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (lote.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Lote'
          const regEx = new RegExp('Lote ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (servidao.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Servidão'
          const regEx = new RegExp('Servidão ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (quadra.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Quadra'
          const regEx = new RegExp('Quadra ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (travessa.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Travessa'
          const regEx = new RegExp('Travessa ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (linha.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Linha'
          const regEx = new RegExp('Linha ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else {
          this.provider.address.street = resp.data.logradouro
        }

        const state = this.states.find(o => o.initials === resp.data.uf)
        this.provider.address.state_id = state.value
        await this.fillCity(state.value, resp.data.localidade)
      }
    },
    async fillCity (stateId, city = null) {
      this.cityOptions = []
      try {
        if (stateId) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/cities/${stateId}`)
          this.cityOptions.push({ value: null, label: 'Selecione' })

          for (const k in resp.data) {
            this.cityOptions.push({ value: resp.data[k].id, label: resp.data[k].city })
          }
          if (city) {
            //procura pelo id, se não encontrar peocura pelo nome (motivo: da api recebo ID e do CEP recebo nome)
            let ct = this.cityOptions.find(x => x.value === city)
            if (!ct) {
              ct = this.cityOptions.find(x => x.label === city)
            }
            this.provider.address.city_id = ct.value
          }
        }
      } catch (error) { console.error(error) }
    }
  }
}
</script>

<style lang="scss">
.popup50 .vs-popup {
  width: 50% !important;
}
.popup80 .vs-popup {
  width: 80% !important;
}
.popup90 .vs-popup {
  width: 90% !important;
}
.noOverflow .vs-popup--content {
  overflow: hidden;
}
.vue_select_drop_size_180 .vs__dropdown-menu {
  max-height: 180px;
}

</style>
