import axios from '@/axios.js'

export default {
  store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/provider`, payload)
        .then((response) => {
          commit('SET', response.data) // SETA NO OBJETO DO ESTADO
          commit('ADD', response.data) // ADICIONA NO ARRAY
          commit('STORE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/provider/${payload.id}`, payload)
        .then((response) => {
          commit('SET', response.data)
          commit('UPDATE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  async fetchAll ({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (!state.providers.length) {
        axios.get(`${process.env.VUE_APP_API_URL}/providers`)
          .then((response) => {
            commit('SET_PROVIDERS', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      } else {
        resolve(state.providers)
      }
    })
  },
  fetchGrid ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/providers`, payload)
        .then((response) => {
          commit('SET_PROVIDERS_GRID', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  delete ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/provider/${payload}`)
        .then((response) => {
          commit('DELETE', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
