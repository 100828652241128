var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: {
            fullscreen: false,
            title: "Cadastrar Despesas",
            active: _vm.localShow,
          },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _c("permission-password", {
            staticStyle: { "z-index": "9999999999" },
            attrs: {
              show: _vm.popupPermissionPassword,
              title: "ALERTA DE BAIXA RETROATIVA",
              icon: "warning",
              actionButtonText: "Prosseguir",
            },
            on: {
              action: function ($event) {
                return _vm.storeDebit(true)
              },
              cancel: function ($event) {
                _vm.popupPermissionPassword = false
              },
            },
          }),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col md:w-1/2 w-full mt-0" }, [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Aluno"),
              ]),
              _c("br"),
              _c("strong", [_vm._v(_vm._s(_vm.student.name))]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "currency",
                      rawName: "v-currency",
                      value: _vm.currencyConfig,
                      expression: "currencyConfig",
                    },
                  ],
                  ref: "total",
                  staticClass: "w-full",
                  attrs: { label: "Valor Total" },
                  model: {
                    value: _vm.debit.total,
                    callback: function ($$v) {
                      _vm.$set(_vm.debit, "total", $$v)
                    },
                    expression: "debit.total",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Nº de Parcelas"),
                ]),
                _c("vs-input-number", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min_value:1",
                      expression: "'required|min_value:1'",
                    },
                  ],
                  staticClass: "mt-2",
                  attrs: {
                    min: "1",
                    "data-vv-as": "Parcelas",
                    "data-vv-scope": "debit",
                    name: "parcelsNumber",
                  },
                  on: { input: _vm.recalculateTotal },
                  model: {
                    value: _vm.debit.parcelsNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.debit, "parcelsNumber", $$v)
                    },
                    expression: "debit.parcelsNumber",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("debit.parcelsNumber"),
                        expression: "errors.has('debit.parcelsNumber')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("debit.parcelsNumber")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "currency",
                      rawName: "v-currency",
                      value: _vm.currencyConfig,
                      expression: "currencyConfig",
                    },
                  ],
                  ref: "firstParcelValue",
                  staticClass: "w-full mt-0",
                  attrs: {
                    label: "Valor 1ª Parcela",
                    disabled: _vm.debit.parcelsNumber < 2,
                  },
                  model: {
                    value: _vm.debit.firstParcelValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.debit, "firstParcelValue", $$v)
                    },
                    expression: "debit.firstParcelValue",
                  },
                }),
                _vm.errorfirstParcelValue
                  ? _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v("Este valor é maior que o total."),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    type: "date",
                    label: "1º Vencimento",
                    "data-vv-as": "1º Vencimento",
                    "data-vv-scope": "debit",
                    name: "expirationDateFirstParcel",
                  },
                  model: {
                    value: _vm.debit.expirationDateFirstParcel,
                    callback: function ($$v) {
                      _vm.$set(_vm.debit, "expirationDateFirstParcel", $$v)
                    },
                    expression: "debit.expirationDateFirstParcel",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has(
                          "debit.expirationDateFirstParcel"
                        ),
                        expression:
                          "errors.has('debit.expirationDateFirstParcel')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Este campo é obrigatório.")]
                ),
                !_vm.isSameOrAfterToday(_vm.debit.expirationDateFirstParcel)
                  ? _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v("A primeira parcela já está vencida."),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Periodicidade"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: [
                      { label: "Mensal", value: "monthly" },
                      { label: "Quinzenal", value: "biweekly" },
                      { label: "Semanal", value: "weekly" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  model: {
                    value: _vm.debit.frequency,
                    callback: function ($$v) {
                      _vm.$set(_vm.debit, "frequency", $$v)
                    },
                    expression: "debit.frequency",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Tipo de Pagamento"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    "data-vv-as": "Tipo de Pagamento",
                    "data-vv-scope": "debit",
                    name: "type_payment_id",
                    options: _vm.typePayments,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: { input: _vm.filterAccounts },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.debit.type_payment_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.debit, "type_payment_id", $$v)
                    },
                    expression: "debit.type_payment_id",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("debit.type_payment_id"),
                        expression: "errors.has('debit.type_payment_id')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Este campo é obrigatório.")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Conta"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    disabled: !_vm.debit.type_payment_id,
                    reduce: (option) => option.value,
                    clearable: false,
                    "data-vv-as": "Conta",
                    "data-vv-scope": "debit",
                    name: "account",
                    options: _vm.accountsFilteredOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.debit.account,
                    callback: function ($$v) {
                      _vm.$set(_vm.debit, "account", $$v)
                    },
                    expression: "debit.account",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("debit.account"),
                        expression: "errors.has('debit.account')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Este campo é obrigatório.")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Plano de contas"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    reduce: (option) => option.value,
                    options: _vm.accountPlans,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.debit.account_plan,
                    callback: function ($$v) {
                      _vm.$set(_vm.debit, "account_plan", $$v)
                    },
                    expression: "debit.account_plan",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-4/5 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Descrição" },
                  model: {
                    value: _vm.debit.observation,
                    callback: function ($$v) {
                      _vm.$set(_vm.debit, "observation", $$v)
                    },
                    expression: "debit.observation",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-6" }, [
              _c(
                "div",
                { staticClass: "mt-0 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "w-full mt-1",
                      attrs: {
                        disabled: _vm.disableGenerateParcels,
                        type: "border",
                        color: "warning",
                        "icon-pack": "feather",
                        icon: "icon-dollar-sign",
                      },
                      on: { click: _vm.generateParcels },
                    },
                    [_vm._v("Gerar Parcelas")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "vs-divider",
            { attrs: { position: "left" } },
            [
              _c("feather-icon", {
                staticClass: "mr-0",
                attrs: { icon: "DollarSignIcon", svgClasses: "w-4 h-4" },
              }),
              _c("span", { staticClass: "mb-4" }, [_vm._v("Parcelas")]),
            ],
            1
          ),
          _vm.debit.transactions.length
            ? _c(
                "vs-table",
                {
                  staticStyle: { overflow: "-webkit-paged-y" },
                  attrs: { data: _vm.debit.transactions, hoverFlat: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ data }) {
                          return [
                            _vm._l(data, function (parcel, i) {
                              return _c(
                                "vs-tr",
                                {
                                  key: i,
                                  staticStyle: { opacity: "1 !important" },
                                },
                                [
                                  _c("vs-td", [
                                    _vm._v(" " + _vm._s(i + 1) + " "),
                                  ]),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("currency-input", {
                                        staticClass:
                                          "w-32 vs-inputx vs-input--input normal hasValue",
                                        attrs: {
                                          currency: { prefix: "R$ " },
                                          valueAsInteger: false,
                                          distractionFree: false,
                                          precision: 2,
                                          autoDecimalMode: true,
                                          valueRange: { min: 0 },
                                          allowNegative: false,
                                        },
                                        on: {
                                          input: function ($event) {
                                            parcel.situation
                                              ? (parcel.value_pay =
                                                  parcel.value_parcel)
                                              : (parcel.value_pay = 0)
                                          },
                                        },
                                        model: {
                                          value: parcel.value_parcel,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              parcel,
                                              "value_parcel",
                                              $$v
                                            )
                                          },
                                          expression: "parcel.value_parcel",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("vs-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.initial",
                                            value: { required: true },
                                            expression: "{ required: true }",
                                            modifiers: { initial: true },
                                          },
                                        ],
                                        staticClass: "w-48",
                                        attrs: {
                                          type: "date",
                                          "data-vv-as": "Vencimento",
                                          "data-vv-scope": "debit",
                                          name: "expiration_date" + i,
                                        },
                                        model: {
                                          value: parcel.expiration_date,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              parcel,
                                              "expiration_date",
                                              $$v
                                            )
                                          },
                                          expression: "parcel.expiration_date",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "debit.expiration_date" + i
                                              ),
                                              expression:
                                                "errors.has('debit.expiration_date'+i)",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [_vm._v("Campo obrigatório")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("v-select", {
                                        staticClass:
                                          "vue_select_drop_size_100 w-48",
                                        attrs: {
                                          clearable: false,
                                          reduce: (option) => option.value,
                                          options: _vm.typePayments,
                                          placeholder: "Selecione",
                                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.filterAccountsParcels(i)
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "no-options",
                                              fn: function ({}) {
                                                return [
                                                  _vm._v(
                                                    "\n                Nenhum resultado encontrado.\n              "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: parcel.type_payment_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              parcel,
                                              "type_payment_id",
                                              $$v
                                            )
                                          },
                                          expression: "parcel.type_payment_id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("v-select", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.initial",
                                            value: {
                                              required: !parcel.account,
                                            },
                                            expression:
                                              "{ required: !parcel.account }",
                                            modifiers: { initial: true },
                                          },
                                        ],
                                        staticClass:
                                          "vue_select_drop_size_100 w-48",
                                        attrs: {
                                          clearable: false,
                                          disabled: !parcel.type_payment_id,
                                          reduce: (option) => option.value,
                                          options:
                                            parcel.accountsFilteredOptions,
                                          placeholder: "Selecione",
                                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                          "data-vv-as": "Conta",
                                          "data-vv-scope": "debit",
                                          name: "account" + i,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "no-options",
                                              fn: function ({}) {
                                                return [
                                                  _vm._v(
                                                    "\n                Nenhum resultado encontrado.\n              "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: parcel.account,
                                          callback: function ($$v) {
                                            _vm.$set(parcel, "account", $$v)
                                          },
                                          expression: "parcel.account",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "debit.account" + i
                                              ),
                                              expression:
                                                "errors.has('debit.account'+i)",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [_vm._v("Campo obrigatório")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("vs-td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-wrap items-center justify-center",
                                      },
                                      [
                                        _c(
                                          "vs-switch",
                                          {
                                            staticClass: "w-28 mt-2",
                                            attrs: {
                                              "icon-pack": "feather",
                                              "vs-icon-on": "icon-check-circle",
                                              "vs-icon-off": "icon-slash",
                                            },
                                            on: {
                                              input: function ($event) {
                                                parcel.situation
                                                  ? (parcel.value_pay =
                                                      parcel.value_parcel)
                                                  : (parcel.value_pay = 0)
                                                parcel.situation
                                                  ? (parcel.payday = _vm.today)
                                                  : (parcel.payday = "")
                                                parcel.situation
                                                  ? ""
                                                  : (parcel.futureDate = false)
                                                _vm.checkPayday(parcel)
                                              },
                                            },
                                            model: {
                                              value: parcel.situation,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  parcel,
                                                  "situation",
                                                  $$v
                                                )
                                              },
                                              expression: "parcel.situation",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                attrs: { slot: "off" },
                                                slot: "off",
                                              },
                                              [_vm._v("ABERTA")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                attrs: { slot: "on" },
                                                slot: "on",
                                              },
                                              [_vm._v("QUITADA")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("vs-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.initial",
                                            value: {
                                              required: parcel.situation,
                                            },
                                            expression:
                                              "{ required: parcel.situation }",
                                            modifiers: { initial: true },
                                          },
                                        ],
                                        staticClass: "w-48",
                                        attrs: {
                                          type: "date",
                                          disabled: !parcel.situation,
                                          "data-vv-as": "Pago em",
                                          "data-vv-scope": "debit",
                                          name: "payday" + i,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.checkPayday(parcel)
                                          },
                                        },
                                        model: {
                                          value: parcel.payday,
                                          callback: function ($$v) {
                                            _vm.$set(parcel, "payday", $$v)
                                          },
                                          expression: "parcel.payday",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "debit.payday" + i
                                              ),
                                              expression:
                                                "errors.has('debit.payday'+i)",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [_vm._v("Campo obrigatório")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: parcel.futureDate,
                                              expression: "parcel.futureDate",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [_vm._v("Data futura não permitida")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            _c(
                              "vs-tr",
                              { staticStyle: { opacity: "1 !important" } },
                              [
                                _c(
                                  "vs-td",
                                  { attrs: { colspan: "8" } },
                                  [
                                    _c("vs-divider"),
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-2 flex flex-wrap items-center",
                                          },
                                          [
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass: "mr-4 mb-2",
                                                attrs: { color: "secondary" },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.localShow = false
                                                  },
                                                },
                                              },
                                              [_vm._v("Cancelar")]
                                            ),
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass: "mr-auto mb-2",
                                                attrs: {
                                                  disabled:
                                                    !_vm.validateDebit ||
                                                    _vm.disableGenerateParcels ||
                                                    _vm.disableSave,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.storeDebit()
                                                  },
                                                },
                                              },
                                              [_vm._v("Salvar")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2127877515
                  ),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [_vm._v("#")]),
                      _c("vs-th", [_vm._v("Valor")]),
                      _c("vs-th", [_vm._v("Vencimento")]),
                      _c("vs-th", [_vm._v("Forma Pagamento")]),
                      _c("vs-th", [_vm._v("Conta")]),
                      _c("vs-th", [_vm._v("Situação")]),
                      _c("vs-th", [_vm._v("Pago Em")]),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          !_vm.debit.transactions.length
            ? _c(
                "vs-alert",
                {
                  staticClass: "mt-4 mb-4",
                  staticStyle: { height: "auto" },
                  attrs: {
                    active: true,
                    color: "warning",
                    "icon-pack": "feather",
                    icon: "icon-info",
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      "Por favor preencha o formulário e gere as parcelas para poder cadastrar!"
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }