export default {
  get (state) {
    const provider = JSON.parse(JSON.stringify(state.provider))
    if (provider.address) provider.address.country = 1 // Brasil
    else provider.address = {
      type_address: 'COM',
      zip_code: null,
      logradouro: null,
      street: null,
      number: null,
      complement: null,
      neighborhood: null,
      city_id: null,
      state_id: null,
      country: 1 //Brasil
    }

    const sortPhonesBy = ['mobile', 'phone', 'other']
    //AJUSTANDO OS TELEFONES VAZIOS
    if (provider.phones) {
      if (!provider.phones.filter(phone => phone.type === 'mobile').length) {
        provider.phones.unshift({id: null, phone: null, type: 'mobile' })
      }
      if (!provider.phones.filter(phone => phone.type === 'phone').length) {
        provider.phones.push({id: null, phone: null, type: 'phone' })
      }
      if (!provider.phones.filter(phone => phone.type === 'other').length) {
        provider.phones.push({id: null, phone: null, type: 'other' })
      }
      //ORDENANDO OS TELEFONES NA SEQUÊNCIA ABAIXO
      provider.phones.sort((a, b) => sortPhonesBy.indexOf(a.type) - sortPhonesBy.indexOf(b.type))
    }

    if (provider.emails && provider.emails.length) provider.email = provider.emails[0].email
    else provider.email = null

    return provider
  }
}
